import * as React from "react";
import { useLocation } from "@reach/router";

import { SEO } from "../components/seo";
import Page from "../components/Page/Page";
import TopBar from "../components/TopBar/TopBar";
import Section from "../components/Section/Section";
import SectionHeading from "../components/SectionHeading/SectionHeading";
import Footer from "../components/Footer/Footer";

import "./LegalPage.css";

const AdvertiseWithUsPage = ({ pageContext }) => {
  return (
    <Page>
      <TopBar showSearch={true} hideCurrency={true} />
      <div style={{ maxWidth: 1000, margin: "0 auto" }}>
        <Section>
          <SectionHeading>Advertise With Us</SectionHeading>
          <div style={{ maxWidth: 900 }}>
            <p>
              <span style={{ color: "#374151" }}>Advertise With Us</span>
            </p>
            <p>
              <span style={{ color: "#374151" }}>
                Welcome to Travel Lingual, the ultimate destination for travel
                enthusiasts and adventure seekers! If you're looking to reach a
                diverse and engaged audience of travelers, explorers, and
                globetrotters, you've come to the right place.
              </span>
            </p>
            <p>
              <span style={{ color: "#374151" }}>
                Why Advertise with Travel Lingual?
              </span>
            </p>
            <p>
              <span style={{ color: "#374151" }}>
                At Travel Lingual, we are passionate about connecting people
                with their dream destinations, providing them with valuable
                travel insights, and helping them create unforgettable
                experiences. Our platform is trusted by thousands of travelers
                who turn to us for inspiration, advice, and recommendations on
                where to go, what to do, and how to make the most of their
                journeys.
              </span>
            </p>
            <p>
              <span style={{ color: "#374151" }}>
                By advertising with Travel Lingual, you gain access to:
              </span>
            </p>
            <ul>
              <li>
                <span style={{ color: "#374151" }}>
                  A Captive Audience: Our readers are actively planning their
                  trips, making travel-related decisions, and seeking products
                  and services to enhance their adventures.
                </span>
              </li>
              <li>
                <span style={{ color: "#374151" }}>
                  Engagement: We foster a community of travel enthusiasts who
                  actively engage with our content, from travel guides and tips
                  to inspiring stories and reviews.
                </span>
              </li>
              <li>
                <span style={{ color: "#374151" }}>
                  Global Reach: Travel Lingual reaches a diverse international
                  audience, providing opportunities to connect with travelers
                  from around the world.
                </span>
              </li>
              <li>
                <span style={{ color: "#374151" }}>
                  Targeted Advertising: We offer various advertising options to
                  suit your needs, allowing you to target specific demographics
                  and niches within the travel industry.
                </span>
              </li>
            </ul>
            <p>
              <span style={{ color: "#374151" }}>
                Advertising Opportunities
              </span>
            </p>
            <p>
              <span style={{ color: "#374151" }}>
                We offer a range of advertising opportunities to suit your goals
                and budget, including:
              </span>
            </p>
            <ul>
              <li>
                <span style={{ color: "#374151" }}>
                  Display Advertising: Showcase your brand through eye-catching
                  banner ads strategically placed on our website.
                </span>
              </li>
              <li>
                <span style={{ color: "#374151" }}>
                  Sponsored Content: Partner with us to create high-quality,
                  engaging sponsored articles that seamlessly integrate your
                  brand with our travel-related content.
                </span>
              </li>
              <li>
                <span style={{ color: "#374151" }}>
                  Newsletter Promotion: Reach our subscribers directly through
                  our newsletter, featuring your products, services, or
                  promotions.
                </span>
              </li>
              <li>
                <span style={{ color: "#374151" }}>
                  Social Media Promotion: Leverage our strong social media
                  presence to amplify your message and engage with our
                  followers.
                </span>
              </li>
              <li>
                <span style={{ color: "#374151" }}>
                  Custom Solutions: Have a unique advertising idea in mind?
                  Let's work together to create a customized campaign that
                  aligns perfectly with your brand.
                </span>
              </li>
            </ul>
            <p>
              <span style={{ color: "#374151" }}>Contact Us</span>
            </p>
            <p>
              <span style={{ color: "#374151" }}>
                Ready to take your travel-related business to the next level?
                Contact our dedicated advertising team today to discuss the
                advertising options and packages that best suit your objectives.
                We look forward to helping you connect with our passionate
                community of travelers and adventurers.
              </span>
            </p>
            <p>
              <span style={{ color: "#374151" }}>
                Join us on this exciting journey to inspire, inform, and empower
                travelers worldwide. Advertise with Travel Lingual and be a part
                of the travel experience.
              </span>
            </p>
          </div>
        </Section>
      </div>
      <Footer />
    </Page>
  );
};

export default AdvertiseWithUsPage;

export const Head = () => {
  const location = useLocation();
  return (
    <SEO
      index
      follow
      title={"Advertise With Us | Travel-Lingual"}
      description={
        "Affiliate Disclaimer: Learn about our affiliate relationships and how they support our mission to provide you with valuable travel information and resources."
      }
      pathname={location.pathname}
    />
  );
};
